import { configureStore } from '@reduxjs/toolkit';
import { wtGetFromLocalStorage } from './helpers/commons/LocalStorage';

const initialState = {
  sidebarShow: true,

  // Stack of error messages
  errorMessages: [],

  // Stack of success messages
  successMessages: [],

  // Show issue/bug reporter
  showingBugReporter: false,

  // Where to go after login is successful
  loggedInLocation: '/',

  // Logged in user object
  authUser: wtGetFromLocalStorage('authUser', null),

  // Logged in user token
  authToken: wtGetFromLocalStorage('authToken', ''),

  // Logged in user token
  remember: wtGetFromLocalStorage('remember', false),

  // Log out system if its idle for some time
  idle_timeout: wtGetFromLocalStorage('idle_timeout', ""),

  // Configuration object
  configurations: wtGetFromLocalStorage('configurations', null),
}


const changeState = (state = initialState, { type, ...rest }) => {

  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'reset':
      return { ...initialState, ...rest };
    default:
      return state;
  }
}

// Create store in a window global var
window.reduxStore = configureStore({ reducer: changeState })

export default window.reduxStore
