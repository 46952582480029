import {
  API_BASE_URL,
  APP_BASE_URL,
  FRONT_BASE_URL,
  AWS_ACCESS_KEY_ID,
  AWS_SECRET_ACCESS_KEY,
  AWS_BUCKET,
  AWS_DEFAULT_REGION,
  AWS_URL,
  AWS_DOMAIN,

} from "../../../config/Request";

/**
 * This object holds the App related global constants.
 *
 * @type object
 */
export const AppConstants = {
  app_code: 'tibit',
  app_name: 'Admin-Tibit.me',
  app_base_url: APP_BASE_URL,
  api_base_url: API_BASE_URL,
  front_base_url: FRONT_BASE_URL,
  aws_access_key_id: AWS_ACCESS_KEY_ID,
  aws_secret_access_key: AWS_SECRET_ACCESS_KEY,
  aws_bucket: AWS_BUCKET,
  aws_default_region: AWS_DEFAULT_REGION,
  aws_url: AWS_URL,
  aws_domain: AWS_DOMAIN
};
